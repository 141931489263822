import { gsap } from "gsap";

//burger

document.querySelector(".nav-button").addEventListener("click", function () {
  var navMenu = document.getElementById("navMenu");
  var burgerIcon = document.querySelector(".burger-icon");
  var closeIcon = document.querySelector(".close-icon");

  navMenu.classList.toggle("active");

  if (navMenu.classList.contains("active")) {
    burgerIcon.style.display = "none";
    closeIcon.style.display = "block";
  } else {
    burgerIcon.style.display = "block";
    closeIcon.style.display = "none";
  }
});

//lightbox
const lightboxes = document.querySelectorAll(".lightbox");
const lightboxImgs = document.querySelectorAll(".lightbox-content");
const thumbnails = document.querySelectorAll(".thumbnail");
const closeBtns = document.querySelectorAll(".close");

function showLightbox(src, lightbox, lightboxImg) {
  lightbox.style.display = "flex";
  lightboxImg.src = src;
}

thumbnails.forEach((thumbnail, index) => {
  thumbnail.addEventListener("click", function () {
    if (!isMobile()) {
      const lightbox = lightboxes[index];
      const lightboxImg = lightboxImgs[index];
      showLightbox(this.src, lightbox, lightboxImg);
    }
  });
});

closeBtns.forEach((btn, index) => {
  btn.addEventListener("click", function () {
    lightboxes[index].style.display = "none";
  });
});

lightboxes.forEach((lightbox) => {
  lightbox.addEventListener("click", function (event) {
    if (event.target === lightbox) {
      lightbox.style.display = "none";
    }
  });
});

function isMobile() {
  return window.innerWidth <= 992;
}

//toggle text hidden
document.querySelectorAll(".toggle-text").forEach(function (toggleLink) {
  toggleLink.addEventListener("click", function (e) {
    e.preventDefault();
    const hiddenText = this.nextElementSibling;

    hiddenText.classList.toggle("active");

    if (hiddenText.classList.contains("active")) {
      this.textContent = "Read less";
    } else {
      this.textContent = "Read more";
    }
  });
});
